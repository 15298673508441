import { Controller } from "@hotwired/stimulus"
import { flashNotice } from "../shopify_app/flash_messages"

export default class extends Controller {
  static values = {
    type: { type: String, default: "product" },
    action: { type: String, default: "associate" },
    selectionIds: { type: Array, default: [] }
  }

  static targets = ["emptyState", "selectedProducts"]

  async open(event) {
    event.preventDefault();

    // Capture the already selected product IDs
    const selectedIds = this.getSelectedProductIds();

    const selected = await shopify.resourcePicker({
      type: this.typeValue,
      multiple: true,
      filter: { variants: false },
      initialSelectionIds: selectedIds
    });

    if (selected) {
      this.hideEmptyState();
      this.displaySelectedProducts(selected.selection);
    }
  }

  hideEmptyState() {
    this.emptyStateTarget.hidden = true;
  }

  displaySelectedProducts(products) {
    this.selectedProductsTarget.innerHTML = "";
    products.forEach(product => {
      const productHtml = this.generateProductHtml(product);
      this.selectedProductsTarget.insertAdjacentHTML('beforeend', productHtml);
    });
  }

  generateProductHtml(product) {
    const optionsHtml = product.options.map(option => this.generateOptionHtml(product.id, product.title, option)).join('');
    const productVariants = product.variants.length === 1 && product.variants[0].title === "Default Title" ? "" : `<div class="options mt-2">${optionsHtml}</div>`;
    return `
      <div class="selected-product border rounded-lg p-4 mb-4" data-product-id="${product.id}">
        <div class="flex justify-start items-center">
          <img src="${product.images[0]?.originalSrc || ''}" alt="${product.title}" width="40" class="border rounded-lg" />
          <h3 class="ml-4">${product.title}</h3>
        </div>
        ${productVariants}
        <div class="mt-2">
          <label for="quantity-${product.id}" class="block text-sm font-medium text-gray-700">Quantity to Add to Bundle</label>
          <input type="number" name="bundle[product_options][${product.id}][quantity]" id="quantity-${product.id}" value="1" min="1" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm sm:text-sm" />
        </div>
        <input type="hidden" name="bundle[product_options][${product.id}][title]" value="${product.title}" />
        <input type="hidden" name="bundle[product_options][${product.id}][description]" value="${product.description}" />
        <input type="hidden" name="bundle[product_options][${product.id}][shopify_product_gid]" value="${product.id}" />
        <input type="hidden" name="bundle[product_options][${product.id}][image]" value="${product.images[0]?.originalSrc || ''}" />
      </div>
    `;
  }

  generateOptionHtml(productId, productName, option) {
    const defaultOptionTitle = `${productName} - ${option.name}`;
    const valuesHtml = option.values.map(value => this.generateOptionValueHtml(productId, option.name, value)).join('');
    return `
      <div class="option mt-2" data-option-id="${option.id}">
        <div class="flex justify-between items-center mb-2">
          <h4 class="option-title text-sm font-normal">${defaultOptionTitle}</h4>
          <a href="#" class="edit-link" data-action="click->resource-picker#editOptionTitle">Edit</a>
        </div>
        <div class="edit-option-title hidden">
          <input type="text" name="bundle[product_options][${productId}][${option.name}][option_title]" value="${defaultOptionTitle}" class="option-title-input" />
          <button type="button" data-action="click->resource-picker#saveOptionTitle" class="btn-save">Save</button>
        </div>
        <div class="values flex flex-wrap">${valuesHtml}</div>
      </div>
    `;
  }

  generateOptionValueHtml(productId, optionName, value) {
    const checkboxId = `${productId}-${optionName}-${value}`;
    return `
      <div class="option-value mr-2 mb-2">
        <input type="checkbox" id="${checkboxId}" name="bundle[product_options][${productId}][${optionName}][values][]" value="${value}" class="hidden" checked data-action="change->resource-picker#enforceSingleSelection" />
        <label for="${checkboxId}" class="cursor-pointer swatch">
          ${value}
        </label>
      </div>
    `;
  }

  getSelectedProductIds() {
    const selectedProductElements = this.selectedProductsTarget.querySelectorAll('.selected-product');
    const selectedIds = Array.from(selectedProductElements).map(el => el.dataset.productId);
    console.log('selectedIds')
    console.log(selectedIds)
    return selectedIds;
  }

  editOptionTitle(event) {
    event.preventDefault();
    const optionElement = event.currentTarget.closest('.option');
    optionElement.querySelector('.option-title').classList.add('hidden');
    optionElement.querySelector('.edit-link').classList.add('hidden');
    optionElement.querySelector('.edit-option-title').classList.remove('hidden');
  }

  saveOptionTitle(event) {
    const optionElement = event.currentTarget.closest('.option');
    const newTitle = optionElement.querySelector('.option-title-input').value;
    optionElement.querySelector('.option-title').textContent = newTitle;
    optionElement.querySelector('.option-title').classList.remove('hidden');
    optionElement.querySelector('.edit-link').classList.remove('hidden');
    optionElement.querySelector('.edit-option-title').classList.add('hidden');
  }

  enforceSingleSelection(event) {
    const optionGroup = event.currentTarget.closest('.option').querySelectorAll('input[type="checkbox"]');
    const checkedCount = Array.from(optionGroup).filter(checkbox => checkbox.checked).length;

    if (checkedCount === 0) {
      event.preventDefault();
      event.currentTarget.checked = true;
    }
  }
}